export const LOG_OUT = 'LOG_OUT';

export const STORE_INFO = 'STORE_INFO';
export const DESTROY_INFO = 'DESTROY_INFO';
export const LOGIN_DIALOG_WAS_CLEARED = 'LOGIN_DIALOG_WAS_CLEARED';

export const LOGIN_REQUEST_SEND = 'LOGIN_REQUEST_SEND';
export const LOGIN_REQUEST_PROCESSING = 'LOGIN_REQUEST_PROCESSING';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';
export const LOGIN_REQUEST_CLEAR_ERROR = 'LOGIN_REQUEST_CLEAR_ERROR';

export const GOOGLE_LOGIN_REQUEST_SEND = 'GOOGLE_LOGIN_REQUEST_SEND';
export const GOOGLE_LOGIN_REQUEST_SEND_PROCESSING =
  'GOOGLE_LOGIN_REQUEST_SEND_PROCESSING';
export const GOOGLE_LOGIN_REQUEST_SEND_SUCCESS =
  'GOOGLE_LOGIN_REQUEST_SEND_SUCCESS';
export const GOOGLE_LOGIN_REQUEST_SEND_FAILURE =
  'GOOGLE_LOGIN_REQUEST_SEND_FAILURE';

export const REGISTRATION_REQUEST_SEND = 'REGISTRATION_REQUEST_SEND';
export const REGISTRATION_REQUEST_PROCESSING =
  'REGISTRATION_REQUEST_PROCESSING';
export const REGISTRATION_REQUEST_SUCCESS = 'REGISTRATION_REQUEST_SUCCESS';
export const REGISTRATION_REQUEST_FAILURE = 'REGISTRATION_REQUEST_FAILURE';

export const REGISTER_WITH_GOOGLE_CONTEXT = 'REGISTER_WITH_GOOGLE_CONTEXT';
export const REGISTER_WITH_GOOGLE_CONTEXT_CLEAR = 'REGISTER_WITH_GOOGLE_CONTEXT_CLEAR';

export const GSI_INITIALIZED = 'GSI_INITIALIZED';


export const VALIDATION_REQUEST_SEND = 'VALIDATION_REQUEST_SEND';
export const VALIDATION_REQUEST_PROCESSING = 'VALIDATION_REQUEST_PROCESSING';
export const VALIDATION_REQUEST_SUCCESS = 'VALIDATION_REQUEST_SUCCESS';
export const VALIDATION_REQUEST_FAILURE = 'VALIDATION_REQUEST_FAILURE';

export const KEY_RETRIEVAL_REQUEST_SEND = 'KEY_RETRIEVAL_REQUEST_SEND';
export const KEY_RETRIEVAL_REQUEST_SUCCESS = 'KEY_RETRIEVAL_REQUEST_SUCCESS';
export const KEY_RETRIEVAL_REQUEST_FAILURE = 'KEY_RETRIEVAL_REQUEST_FAILURE';

export const KEY_CREATION_REQUEST_SEND = 'KEY_CREATION_REQUEST_SEND';
export const KEY_CREATION_REQUEST_PROCESSING =
  'KEY_CREATION_REQUEST_PROCESSING';
export const KEY_CREATION_REQUEST_SUCCESS = 'KEY_CREATION_REQUEST_SUCCESS';
export const KEY_CREATION_REQUEST_FAILURE = 'KEY_CREATION_REQUEST_FAILURE';

export const UPDATE_USER_INFO_REQUEST_SEND = 'UPDATE_USER_INFO_REQUEST_SEND';
export const UPDATE_USER_INFO_REQUEST_SUCCESS =
  'UPDATE_USER_INFO_REQUEST_SUCCESS';
export const UPDATE_USER_INFO_REQUEST_FAILURE =
  'UPDATE_USER_INFO_REQUEST_FAILURE';

export const REFRESH_LOGIN = 'REFRESH_LOGIN';

export const PROMPT_GOOGLE_LOGIN = 'PROMPT_GOOGLE_LOGIN';

export const RECOVER_PASSWORD_REQUEST_SEND = 'RECOVER_PASSWORD_REQUEST_SEND';

export const CHOOSE_PASSWORD_REQUEST_SEND = 'CHOOSE_PASSWORD_REQUEST_SEND';
export const CHOOSE_PASSWORD_REQUEST_SUCCESS =
  'CHOOSE_PASSWORD_REQUEST_SUCCESS';
export const CHOOSE_PASSWORD_REQUEST_FAILURE =
  'CHOOSE_PASSWORD_REQUEST_FAILURE';
export const CHOOSE_PASSWORD_REQUEST_RESET = 'CHOOSE_PASSWORD_REQUEST_RESET';

export const CONTACT_US_REQUEST_SEND = 'CONTACT_US_REQUEST_SEND';
export const CONTACT_US_REQUEST_SUCCESS = 'CONTACT_US_REQUEST_SUCCESS';
export const CONTACT_US_REQUEST_FAILURE = 'CONTACT_US_REQUEST_FAILURE';

export const NOMINATE_NEW_DATA_REQUEST_SEND=
  'NOMINATE_NEW_DATA_REQUEST_SEND';
export const NOMINATE_NEW_DATA_REQUEST_SUCCESS=
  'NOMINATE_NEW_DATA_REQUEST_SUCCESS';
export const NOMINATE_NEW_DATA_REQUEST_FAILURE =
  'NOMINATE_NEW_DATA_REQUEST_FAILURE';

export const CHANGE_PASSWORD_REQUEST_SEND = 'CHANGE_PASSWORD_REQUEST_SEND';

export const CHANGE_EMAIL_REQUEST_SEND = 'CHANGE_EMAIL_REQUEST_SEND';

export const GUEST_TOKEN_REQUEST_SEND = 'GUEST_TOKEN_REQUEST_SEND';
export const USER_IS_GUEST_SET = 'USER_IS_GUEST_SET';

export const INGEST_COOKIES = 'INGEST_COOKIES';
export const UPDATE_STATE_FROM_COOKIES = 'UPDATE_STATE_FROM_COOKIES';

export const REQUEST_USER_API_CALLS_SEND = 'REQUEST_USER_API_CALLS_SEND';
export const REQUEST_USER_API_CALLS_STATUS = 'REQUEST_USER_API_CALLS_STATUS';

export const SET_LAST_DATASET_TOUCH = 'SET_LAST_DATASET_TOUCH';
export const CLEAR_LAST_DATASET_TOUCH = 'CLEAR_LAST_DATASET_TOUCH';

export const FETCH_SUBSCRIPTIONS_SEND = 'FETCH_SUBSCRIPTIONS_SEND';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_FAILURE';
export const SET_FETCH_SUBSCRIPTIONS_REQUEST_STATUS = 'SET_FETCH_SUBSCRIPTIONS_REQUEST_STATUS';


export const CREATE_SUBSCRIPTION_SEND = 'CREATE_SUBSCRIPTION_SEND';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const SET_CREATE_SUBSCRIPTION_REQUEST_STATUS = 'SET_CREATE_SUBSCRIPTION_REQUEST_STATUS';

export const DELETE_SUBSCRIPTIONS_SEND = 'DELETE_SUBSCRIPTIONS_SEND';
export const DELETE_SUBSCRIPTIONS_SUCCESS = 'DELETE_SUBSCRIPTIONS_SUCCESS';
export const DELETE_SUBSCRIPTIONS_FAILURE = 'DELETE_SUBSCRIPTIONS_FAILURE';
export const SET_DELETE_SUBSCRIPTIONS_REQUEST_STATUS = 'SET_DELETE_SUBSCRIPTIONS_REQUEST_STATUS';

export const CHANGE_NEWS_SUBSCRIPTION = 'CHANGE_NEWS_SUBSCRIPTION';
export const CHANGE_NEWS_SUBSCRIPTION_SUCCESS = 'CHANGE_NEWS_SUBSCRIPTION_SUCCESS';
export const SET_CHANGE_NEWS_SUBSCRIPTION_REQUEST_STATUS = 'SET_CHANGE_NEWS_SUBSCRIPTION_REQUEST_STATUS';

export const QUEUE_ACTION_TO_RESUME = 'QUEUE_ACTION_TO_RESUME';
export const CLEAR_RESUME_ACTION = 'CLEAR_RESUME_ACTION';
